<template>
  <div class="analysis">
    <h4 class="title">
      Score Breakdown
      <el-button
        size="small"
        type="success"
        v-show="showPer"
        @click="showPer = false"
      >
        Show details
      </el-button>
      <el-button
        size="small"
        type="danger"
        v-show="!showPer"
        @click="showPer = true"
      >
        Hide details
      </el-button>
    </h4>
    <div v-for="(passage, title, index) in analysis.answers" :key="index">
      <hr />
      <h4>{{ analysis.exam }} {{ sectionArr[title] }}:</h4>
      <div
        class="answer-table"
        v-for="i in Math.ceil(passage.length / lineCount)"
        :key="i"
      >
        <table class="table table-bordered">
          <thead>
            <tr class="rnum_1">
              <th class="green-td">#</th>
              <th class="green-td">Students</th>
              <th class="green-td">Correct Answer</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(question, questionIndex) in passage"
              :key="questionIndex"
              v-show="
                (i - 1) * lineCount <= questionIndex &&
                  questionIndex < i * lineCount
              "
            >
              <td class="green-td">{{ questionIndex + 1 }}</td>
              <td>
                <div
                  v-if="question"
                  :class="
                    showAnswerCorrectPer(question.correct, question.total)
                  "
                >
                  <el-tooltip placement="top" v-show="showPer">
                    <div
                      slot="content"
                      v-html="
                        showWrongAns(
                          question.answers[0][0],
                          question.student_answers
                        )
                      "
                    ></div>
                    <b>{{ getPer(question.correct / question.total) }}% </b>
                  </el-tooltip>
                  <div v-show="!showPer">
                    <el-tooltip
                      :content="showName(true, question)"
                      placement="top"
                    >
                      <b class="text-success">
                        {{ question.correct }}
                      </b>
                    </el-tooltip>
                    /
                    <b>
                      {{ question.omit }}
                    </b>
                    /
                    <el-tooltip
                      :content="showName(false, question)"
                      placement="top"
                    >
                      <b class="text-danger">
                        {{ question.wrong }}
                      </b>
                    </el-tooltip>
                  </div>
                </div>
              </td>
              <td>
                <div v-if="question">
                  <router-link
                    v-if="type === 'adaptive'"
                    :to="{
                      name: 'QuestionExplanation',
                      params: { questionId: question.question_id }
                    }"
                    style="display:inline-block;width: 100%;height: 100%;"
                  >
                    <b class="text-success">{{ question.answers[0][0] }}</b>
                  </router-link>
                  <router-link
                    v-else
                    :to="{
                      name: 'ClassQuestionExplanation',
                      params: { questionId: question.question_id },
                      query: {
                        user_exam_id: user_exam_id_arr,
                        practiceType: 'composePractices',
                        order: questionIndex + 1,
                        passageIndex: index + 1
                      }
                    }"
                    style="display:inline-block;width: 100%;height: 100%;"
                  >
                    <b class="text-success">{{ question.answers[0][0] }}</b>
                  </router-link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { instant, user } from "@ivy-way/material";

export default {
  metaInfo() {
    return {
      title: "Analysis - Detail - Ivy-Way Academy"
    };
  },

  components: {},

  mixins: [],

  props: ["analysis", "avgScore", "type"],
  data() {
    return {
      lineCount: 10,
      showScores: false,
      showPer: true,
      showAnswer: true,
      progress: {
        progress_total_score: 0,
        progress_section_english_score: 0,
        progress_section_math_score: 0,
        progress_reading_score: 0,
        progress_math_score: 0,
        progress_aih_score: 0,
        progress_ais_score: 0,
        progress_coe_score: 0,
        progress_wic_score: 0,
        progress_eoi_score: 0,
        progress_hoa_score: 0,
        progress_psada_score: 0,
        progress_ptam_score: 0,
        progress_sec_score: 0,
        progress_essay_reading: 0,
        progress_essay_analysis: 0,
        progress_essay_writing: 0
      },
      progressType: 1,
      sectionArr: {
        "english 1": "English Module 1",
        "english 2": "English Module 2",
        "math 3": "Math Module 1",
        "math 4": "Math Module 2"
      }
    };
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    user() {
      return user;
    },
    user_exam_id_arr() {
      let arr = [];
      if (
        this.analysis &&
        this.analysis.scores &&
        this.analysis.scores.length > 0
      ) {
        this.analysis.scores.forEach(item => {
          arr.push(item.user_exam_id);
        });
      }
      return arr.join(",");
    }
  },
  watch: {},

  mounted() {},

  methods: {
    showScore(total) {
      let score = "";
      score = Math.round(parseFloat(total));
      return score;
    },
    getPer(val) {
      let str = 0;
      if (val > 0) {
        str = Math.round(val * 100);
      }
      return str;
    },
    showWrongAns(cAns, studentsAnswer) {
      let text = "None";
      let ctext = "";
      let wtext = "";
      let hasCorrect = false;
      let hasWrong = false;
      let i = 0;
      for (let key in studentsAnswer) {
        let name = "";
        if (Array.isArray(studentsAnswer[key])) {
          studentsAnswer[key].forEach((student, index) => {
            if (studentsAnswer[key].length === index + 1) {
              name += student;
            } else {
              name += student + ", ";
            }
          });
        }
        if (key == cAns) {
          hasCorrect = true;
          ctext += `Correct <br /><b class="text-success">${cAns} (${name})</b>`;
        } else if (key != cAns && key != "X") {
          hasWrong = true;
          if (i === 0) {
            wtext += `Wrong <br /><b class="text-danger">${key} (${name})</b>`;
          } else {
            wtext += ` <br /><b class="text-danger">${key} (${name})</b>`;
          }
          i++;
        }
      }
      if (hasCorrect && hasWrong) {
        text = ctext + "<br /><br />" + wtext;
      }
      if (!hasCorrect && hasWrong) {
        text = wtext;
      }
      if (hasCorrect && !hasWrong) {
        text = ctext;
      }
      return text;
    },
    showName(isCorrect, obj) {
      let students = [];
      if (isCorrect) {
        for (let key in obj.student_answers) {
          if (obj.answers[0].indexOf(key) > -1) {
            students = students.concat(obj.student_answers[key]);
          }
        }
      } else {
        for (let key in obj.student_answers) {
          if (obj.answers[0].indexOf(key) < 0) {
            students = students.concat(obj.student_answers[key]);
          }
        }
      }
      if (students.length > 0) {
        return students.join(", ");
      } else {
        return "None";
      }
    },
    showAnswerCorrectPer(correct, total) {
      let per = 0;
      per = this.getPer(correct / total);
      if (parseFloat(per) < 25) {
        return "correct-style correct-0";
      } else if (parseFloat(per) >= 25 && parseInt(per) < 50) {
        return "correct-style correct-25";
      } else if (parseFloat(per) >= 50 && parseInt(per) < 75) {
        return "correct-style correct-50";
      } else if (parseFloat(per) >= 75 && parseInt(per) < 100) {
        return "correct-style correct-75";
      } else if (parseInt(per) === 100) {
        return "correct-style correct-100";
      }
    }
  }
};
</script>

<style scoped>
.analysis {
  padding: 20px 0;
}
.title {
  font-size: 30px;
}
.total p,
.total ul {
  margin: 0px;
}

.total {
  margin-top: 30px;
}
.total .title {
  font-size: 30px;
  color: #43a06b;
  margin-bottom: 20px;
}
.total_under {
  padding: 0px;
}
.total_above .score {
  font-size: 60px;
  color: #222;
  font-weight: 700;
  line-height: 70px;
  height: 70px;
}
.total .score + p {
  margin-top: 25px;
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  border-left: 4px solid #ccc;
}
.sat_left > div > span {
  display: inline-block;
  position: absolute;
  left: 290px;
  top: 28px;
}
.sat_left > div > p:first-child {
  font-size: 140px;
  font-weight: 700;
  color: #43a06b;
  line-height: 140px;
  margin-bottom: 0px;
}
.sat_right {
  padding-left: 0px;
  padding-top: 30px;
  border-bottom: 1px solid#DCDFE6;
}
.sat_right ul {
  clear: both;
}
.sat_right ul li {
  font-size: 20px;
  line-height: 30px;
}
.sat_right img {
  width: 90%;
}
/* .green-td {
  width: 96px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #fff;
  background-color: #43a06b;
}
.table td,
.table th {
  height: 20px;
  padding: 2px 5px;
  text-align: center;
  vertical-align: middle;
  border: #4fb81e 2px solid;
}
#testresult.table td,
#testresult.table th {
  padding: 0;
  min-width: 30px;
  font-weight: 500;
  border: #dee2e6 2px solid;
} */
.demo-block-control {
  /* border-top: 1px solid #eaeefb; */
  height: 44px;
  line-height: 44px;
  box-sizing: border-box;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  text-align: center;
  margin-top: -1px;
  color: #4fb81e;
  cursor: pointer;
  position: relative;
}
.demo-block-control:hover {
  /* color: #ff9040; */
  background-color: #f9fafc;
}

.correct-100 {
  background-color: #42a16ae6;
  color: white;
}
.correct-75 {
  background-color: #42a16ae6;
  color: white;
}
.correct-50 {
  background-color: #7cc89ccf;
  color: white;
}
.correct-25 {
  background-color: #fad5d5;
  color: white;
}
.correct-0 {
  background-color: #ffa3a3;
  color: white;
}
/* .correct-style {
  padding: 8px 5px;
} */

.table-bordered {
  border: none;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  vertical-align: middle;
  text-align: center;
}
.table .green-td {
  text-align: center;
  color: #fff;
  font-weight: bold;
  background-color: var(--themeColor);
}
td a {
  display: inline-block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
}
td a:hover {
  background-color: #eee;
}
table.table td,
table.table th {
  padding: 0 !important;
  height: 45px;
  line-height: 45px;
}
.answer-table {
  display: flex;
  justify-content: center;
}
.answer-table .table {
  width: auto;
  margin: 1rem 0;
}
@media screen and (max-width: 768px) {
  .answer-table {
    width: 100%;
  }
  .answer-table table.table {
    width: 100%;
    margin: 0;
  }
}

@media screen and (min-width: 768px) {
  .table {
    position: relative;
    width: 100%;
    display: block;
  }
  thead {
    float: left;
  }
  tbody tr {
    display: inline-block;
  }
  th,
  td {
    height: 45px;
    padding: 0;
    line-height: 45px;
    display: block;
  }
  th {
    width: 150px;
  }
  .table td {
    padding: 0;
    min-width: 90px;
    max-width: 100px;
  }
  td a {
    cursor: pointer;
  }
}
</style>
